import React, { useState, useEffect } from "react";

const DepartmentFilter = ({
  departments,
  setSelectedFilters,
  selectedFilters,
  isInitialLoadForFilter,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selectedFilters.departments.length > 0) {
      const presetOption = departments.find((department) =>
        selectedFilters.departments.includes(department.label)
      );
      setSelectedOption(presetOption);
    }
  }, [departments, selectedFilters]);

  const handleButtonClick = (department) => {
    isInitialLoadForFilter.current = false;

    // If the same department is clicked again, deselect it
    const newSelected =
      selectedOption && selectedOption.value === department.value
        ? null
        : department;

    setSelectedOption(newSelected);

    const selectedValues = newSelected ? [newSelected.label] : [];
    setSelectedFilters((prevState) => ({
      ...prevState,
      departments: selectedValues,
    }));
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "8px" }}>
        {departments.map((dept) => {
          const isActive = selectedOption && selectedOption.value === dept.value;
          return (
            <button
              key={dept.value}
              onClick={() => handleButtonClick(dept)}
              style={{
                backgroundColor: isActive ? dept.color : "white",
                border: "1px solid #ccc",
                padding: "8px 12px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              {dept.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DepartmentFilter;
