import { scheduler } from "dhtmlx-scheduler";
import "dhtmlx-scheduler/codebase/dhtmlxscheduler.css";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import profile from "../assets/images/profile.png";
import { showConfirm } from "../components/compound/ShowConfirm";
import {
  dkFormatDate,
  getCurrentWeek,
} from "../utils/common";
import { useAuthContext } from "./useAuthContext";
import useDashboard from "./useDashboard";

import { BACKEND_URL } from "../utils/config";

function useScheduler() {
  const { user, dispatch } = useAuthContext();
  const { data, filters, isLoading, updateFilters } = useDashboard();
  const imageUrl = user.image_link ? user.image_link : profile;
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [defaultView, setDefaultView] = useState("week");
  const [currentView, setCurrentView] = useState("week");
  const [schedulerLoading, setSchedulerLoading] = useState(false);
  const currentWeek = getCurrentWeek();
  const [currentViewDate, setCurrentViewDate] = useState(
    `${dkFormatDate(currentWeek.weekStart)} - ${dkFormatDate(
      currentWeek.weekEnd
    )}`
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [weekNumber, setWeekNumber] = useState("");
  const [collapseSideBar, setCollapseSideBar] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const savedFilters = localStorage.getItem("schedulerFilters");
    return savedFilters
      ? JSON.parse(savedFilters)
      : {
        departments: [],
        salesRep: [],
      };
  });
  const isInitialLoad = useRef(true);
  const isInitialLoadForFilter = useRef(true);
  const container = useRef(null);
  const hasInitRef = useRef(false); // Track if scheduler is already initialized
  const isViewChangeAttached = useRef(false); // Track if onViewChange event is already attached

  const initializeScheduler = () => {
    scheduler.skin = "terrace";
    scheduler.config.drag_move = false;
    scheduler.config.drag_resize = false;
    scheduler.config.icons_select = [];
    scheduler.config.hour_size_px = 90;
    scheduler.config.first_hour = 7;
    scheduler.config.last_hour = 18;
    scheduler.i18n.setLocale("da");

    scheduler.plugins({
      tooltip: true,
    });

    scheduler.addMarkedTimespan({
      days: [0, 6],
      zones: "fullday",
      css: "weekend-highlight",
      type: "weekend-highlight",
      html: "<div class='weekend-text'>Weekend</div>",
    });

    scheduler.attachEvent("onBeforeEventCreated", () => false);
    scheduler.attachEvent("onDblClick", (id) => {
      const event = scheduler.getEvent(id);
      if (!event.is_out_of_office) {
        setSelectedEvent(event);
        setIsModalOpen(true);
        return false;
      }

    });

    scheduler.attachEvent("onBeforeEventChanged", () => false);

    scheduler.templates.event_text = (start, end, event) => {
      const durationInMinutes = (end - start) / (1000 * 60); // Calculate duration in minutes

      const formattedAddress = formatAddress(event.address);

      if (durationInMinutes < 60) {
        return `<div class="custom-event-template" title="${formattedAddress}">
                            <div class="event-details">
                                <span class="event-title"></span> <!-- Don't show text for short events -->
                            </div>
                        </div>`;
      } else {
        return `<div class="custom-event-template" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            <div class="event-details">
                                <span class="event-title">${formattedAddress}</span>
                            </div>
                        </div>`;
      }
    };
    scheduler.templates.week_scale_date = function (date) {
      const weekdays = [
        "Søndag",
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag",
      ];
      const dayName = weekdays[date.getDay()];
      return `<div class='custom-week-header'>
                        <span class='custom-week-header-day'>${dayName}</span> <br>
                        <span class='custom-week-header-date'>${scheduler.date.date_to_str(
        "%d %M"
      )(date)}</span>
                    </div>`;
    };

    scheduler.attachEvent("onEmptyClick", function (date, e) {
      return false;
    });
  };

  scheduler.renderEvent = function (container, ev) {
    if (ev.is_out_of_office) {
      container.innerHTML = `<div class="centered-content">
                <div class="event-ooo">
              <strong>Out Of Office</strong>
            </div>
          </div>
          `;
      return true;
    }
    const department = ev.department.join(", ");
    const container_width = container.style.width;

    const state = scheduler.getState();

    let html =
      "<div class='dhx_event_move my_event_move' style='width: " +
      container_width +
      "'></div>";
    html += "<div class='my_event_body'>";
    html += "<div class='my_event_body_wrapper'>";
    if ((ev.end_date - ev.start_date) / 60000 > 40) {
      html += "<span class='event_date " + department + "'>";
      html += scheduler.templates.event_date(ev.start_date) + "</span>";
      html += "<span class='event_date " + department + "'>";
      html += scheduler.templates.event_date(ev.end_date) + "</span>";
    } else {
      html += "<span class='event_date " + department + "'>";
      html += scheduler.templates.event_date(ev.start_date) + "</span>";
    }

    if (Array.isArray(ev.sales_rep_details)) {
      let displayRep = ev.sales_rep_details;
      let hiddenRep = [];
      if (ev.sales_rep_details.length > 2 && state.mode === "week") {
        displayRep = ev.sales_rep_details.slice(0, 1);
        hiddenRep = ev.sales_rep_details.slice(1);
      }
      displayRep.forEach(function (rep) {
        if (rep.image)
          html +=
            "<img src='" +
            BACKEND_URL +
            rep.image +
            "' class='event-booker-image' />";
      });

      if (hiddenRep.length > 0) {
        html +=
          '<span class="event-rep-badge">+' + hiddenRep.length + "</span>";
      }
    }
    // if (Array.isArray(ev.booker_image)) {
    //   ev.booker_image.forEach(function (imageUrl) {
    //     html += "<img src='" + imageUrl + "' class='event-booker-image' />";
    //   });
    // }
    if ((ev.end_date - ev.start_date) / 60000 < 60) {
      const formattedAddress = formatAddress(ev.address);
      html += '<span class="event-title">' + formattedAddress + "</span>";
    }
    html += "</div>";
    html +=
      "<span>" +
      scheduler.templates.event_text(ev.start_date, ev.end_date, ev) +
      "</span>" +
      "</div>";
    html +=
      "<div class='dhx_event_resize my_event_resize' style='width: " +
      container_width +
      "'></div>";
    container.innerHTML = html;
    return true;
  };

  scheduler.templates.event_bar_text = (start, end, event) => {
    if (event.is_out_of_office) {
      return `<div class="bar-ooo"><strong>Out Of Office</strong></div>`;
    } else {
      const formattedAddress = formatAddress(event.address);
      return `<div class="bar-ooo"><strong>` + formattedAddress + `</strong></div>`;
    }
  };

  const setScheduler = () => {
    if (!container.current || isLoading) {
      return;
    }
    initializeScheduler();

    scheduler.init(container.current, defaultDate, defaultView);

    scheduler.templates.month_date = function (start, end, event) {
      let startDate = scheduler.date.date_to_str("%d %M")(start);
      let endDate = scheduler.date.date_to_str("%d %M")(end);
      return startDate + " - " + endDate + ": " + event.text;
    };

    scheduler.config.dblclick_create = false;
    scheduler.templates.event_class = function (start, end, event) {
      if (!event.is_out_of_office) {
        return event.department[0];
      } else {
        return "ooo-event";
      }
    };

    hasInitRef.current = true;
    if (!isViewChangeAttached.current) {
      scheduler.attachEvent("onViewChange", (new_mode, new_date) => {
        setDefaultDate(new_date);
        setDefaultView(new_mode);
        updateDisplayedDateRange();
        if (new_mode === "week") {
          const weekNumber = getWeekNumber(new_date);
          setWeekNumber(`Uge ${weekNumber}`);
        } else {
          setWeekNumber("");
        }
        const state = scheduler.getState();
        debouncedUpdateFilters(state.min_date, state.max_date);
      });
      isViewChangeAttached.current = true; // Mark as attached
    }
    scheduler.attachEvent("onClick", function (id, e) {
      return false; // This will prevent the click action
    });

    scheduler.templates.tooltip_text = function (start, end, event) {
      if (event.is_out_of_office) {
        let sales_rep = event.sales_rep_details[0];
        return (
          "<b>Sales rep:</b> " + sales_rep.sales_representative_name +
          "<br/><b>Status:</b> Uden for kontoret" +
          "<br/><b>Startdato:</b> " +
          scheduler.templates.tooltip_date_format(start) +
          "<br/><b>Slutdato:</b> " +
          scheduler.templates.tooltip_date_format(end) +
          "<br/><b>Årsag:</b> " +
          (event.category.text || "Ikke angivet")
        );
      } else {
        const salesRepNames = event.sales_rep_details
          .map((rep) => rep.sales_representative_name)
          .join(", ");

        return (
          "<b>Virksomhedsnavn:</b> " +
          event.title +
          "<br/><b>Startdato:</b> " +
          scheduler.templates.tooltip_date_format(start) +
          "<br/>" +
          "<b>Slutdato:</b> " +
          scheduler.templates.tooltip_date_format(end) +
          "<br/>" +
          "<b>Salgsrepræsentantens navn:</b> " +
          salesRepNames +
          "<br/>" +
          "<b>Adresse:</b> " +
          event.address.value
        );
      }
    };

  };

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      const currentWeek = getCurrentWeek();
      updateFilters(currentWeek.weekStart, currentWeek.weekEnd);
      setWeekNumber("Uge " + getWeekNumber(currentWeek.weekStart));
    }
  }, []);

  useEffect(() => {
    // When collapseSideBar changes, re-render the scheduler to adjust its layout
    if (hasInitRef.current && container.current) {
      handleViewChange(currentView);
      setTimeout(() => {
        handleViewChange(currentView);
      }, 600);
      // Alternatively: scheduler.updateView();
      // Or: scheduler.setCurrentView(scheduler.getState().date, scheduler.getState().mode);
    }
  }, [collapseSideBar]);


  useEffect(() => {
    if (isLoading || !container.current) {
      return;
    }
    setScheduler();
  }, [isLoading]); // Trigger when loading state changes

  useEffect(() => {
    setSchedulerLoading(true);
    if (data !== undefined) {
      const filteredData = memoizedFilteredData;
      setTimeout(() => {
        setSchedulerLoading(false);
        scheduler.clearAll();
        scheduler.parse(filteredData);
      }, 500);
    }
    localStorage.setItem("schedulerFilters", JSON.stringify(selectedFilters));
  }, [data, selectedFilters]);

  const debouncedUpdateFilters = useCallback(
    debounce(async (minDate, maxDate) => {
      updateFilters(minDate, maxDate);
    }, 300),
    [updateFilters]
  );
  const updateDisplayedDateRange = () => {
    const state = scheduler.getState();
    let currentViewDate = state.date;
    let formattedDate = "";
    if (state.mode === "week") {
      const startOfWeek = new Date(state.min_date);
      const endOfWeek = new Date(state.max_date);
      formattedDate = `${dkFormatDate(startOfWeek)} - ${dkFormatDate(
        endOfWeek
      )}`;
    } else if (state.mode === "day") {
      formattedDate = dkFormatDate(currentViewDate);
    } else if (state.mode === "month") {
      formattedDate = dkFormatDate(currentViewDate);
    }
    setCurrentViewDate(formattedDate);
  };

  const getWeekNumber = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  };

  const goPrev = () => {
    scheduler.setCurrentView(
      scheduler.date.add(scheduler._date, -1, scheduler._mode)
    );
    const state = scheduler.getState();
    debouncedUpdateFilters(state.min_date, state.max_date);
  };

  const goNext = () => {
    scheduler.setCurrentView(
      scheduler.date.add(scheduler._date, 1, scheduler._mode)
    );
    const state = scheduler.getState();
    debouncedUpdateFilters(state.min_date, state.max_date);
  };

  const handleViewChange = (viewMode) => {
    setCurrentView(viewMode);
    scheduler.setCurrentView(null, viewMode);
    const state = scheduler.getState();
    debouncedUpdateFilters(state.min_date, state.max_date);
    scheduler.updateView(); // Ensure scheduler updates after changing view mode
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  const filterData = (data, filters) => {
    return data?.filter((item) => {
      const matchesDepartment =
        filters.departments.length === 0 ||
        item.department.some((dept) => filters.departments.includes(dept));
      const salesRepMatch =
        filters.salesRep.length === 0 ||
        item.sales_rep_details.some((rep) =>
          filters.salesRep.includes(rep.item_id)
        );
      return matchesDepartment && salesRepMatch;
    });
  };

  const memoizedFilteredData = useMemo(
    () => filterData(data, selectedFilters),
    [data, selectedFilters]
  );
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const Logout = async () => {
    try {
      if (
        await showConfirm({
          confirmation: "Er du sikker på, at du vil logge ud?",
        })
      ) {
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      console.log("Cancelled the Logout");
    }
  };
  const handleSideBarViewChange = () => {
    if (collapseSideBar) {
      setCollapseSideBar(false);
    } else {
      setCollapseSideBar(true);
    }
  };
  return {
    isLoading,
    collapseSideBar,
    handleSideBarViewChange,
    filters,
    setSelectedFilters,
    selectedFilters,
    currentViewDate,
    handleViewChange,
    goPrev,
    goNext,
    isOpen,
    toggleDropdown,
    user,
    Logout,
    schedulerLoading,
    weekNumber,
    isModalOpen,
    closeModal,
    selectedEvent,
    imageUrl,
    container,
    defaultView,
    isInitialLoadForFilter,
  };
}

export default useScheduler;

function formatAddress(address) {
  // Extract values with fallbacks to empty strings if they're not present
  const { postal_code = "", city = "", value = "" } = address;

  // Split the 'value' field to separate street, city, etc., in case it includes additional data
  const [street] = value.split(",").map((part) => part.trim());

  // Assemble the formatted address parts in the order: postal code, city, street
  const formattedParts = [postal_code, city, street].filter((part) => part);

  // Join parts with ", " only for non-empty values
  return formattedParts.join(", ");
}
